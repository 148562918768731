import { useQuery } from '@tanstack/react-query';
import { getWorks } from 'api/work';
import { groupBy, isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type MenuItem = {
  id?: string;
  label: string;
  link?: string;
  isHeader?: boolean;
  icon?: string;
  subItems?: MenuItem[];
  stateVariables?: boolean;
  click?: (e: any) => void;
  parentId?: string;
};

const Navdata = () => {
  const history = useNavigate();

  const [expanded, setExpanded] = useState('');
  const [workMenu, setWorkMenu] = useState<MenuItem[]>([
    {
      id: 'loading',
      label: 'Loading...',
      link: '#'
    }
  ]);
  const { data, isLoading } = useQuery(getWorks({ paginate: false }));
  const { data: work } = data || { data: [] };

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute('sub-items')) {
      const ul: any = document.getElementById('two-column-menu');
      const iconItems: any = ul.querySelectorAll('.nav-icon.active');
      const activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove('active');
        const id = item.getAttribute('sub-items');
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove('show');
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel');

    if (expanded === 'Widgets') {
      history('/widgets');
      document.body.classList.add('twocolumn-panel');
    }
  }, [history, expanded]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!isEmpty(work)) {
      const grouped = groupBy(work, 'category_name');

      const workMenu: MenuItem[] = [];
      let isExpanded = '';
      Object.keys(grouped).forEach((category) => {
        const label = !isEmpty(category) && category !== 'null' && category != 'undefined' ? category : 'Uncategorized';
        if (isEmpty(expanded) && isEmpty(isExpanded)) {
          isExpanded = label;
          setExpanded(label);
        }

        workMenu.push({
          label,
          id: label,
          icon: 'ri-pages-line',
          click: function (e: any) {
            e.preventDefault();
            setExpanded(label);
            updateIconSidebar(e);
          },
          stateVariables: isEmpty(expanded) ? isExpanded === label : expanded === label,
          subItems: grouped[category].map((item: any) => ({
            id: item.uid,
            label: item.name,
            link: `/tasks/${item.uid}`,
            parentId: label
          }))
        });
      });

      setWorkMenu(workMenu);
    } else {
      const emptyMenu = [
        {
          id: 'empty',
          label: 'Task types not set up...',
          link: '#'
        }
      ];
      setWorkMenu(emptyMenu);
    }
  }, [work, expanded]);

  const menuItems: any = useMemo<MenuItem[]>(
    () => [
      {
        id: 'home',
        label: 'Home',
        link: '/',
        click: function (e: any) {
          setExpanded('home');
        }
      },
      {
        label: 'Work',
        isHeader: true
      },
      ...workMenu,
      {
        label: 'Settings',
        isHeader: true
      },
      {
        id: 'profile',
        label: 'My Profile',
        link: '/profile',
        click: function (e: any) {
          setExpanded('profile');
        }
      },
      {
        id: 'clients',
        label: 'Clients',
        link: '/clients',
        click: function (e: any) {
          setExpanded('clients');
        }
      },
      {
        id: 'portals',
        label: 'Portal Credentials',
        link: '/portals',
        click: function (e: any) {
          setExpanded('portals');
        }
      },
      {
        label: 'Administration',
        isHeader: true
      },
      {
        id: 'users',
        label: 'Staff',
        link: '/users',
        click: function (e: any) {
          setExpanded('users');
        }
      },
      {
        id: 'activity',
        label: 'Activity',
        link: '/activity',
        click: function (e: any) {
          setExpanded('activity');
        }
      },
      {
        id: 'tasks',
        label: 'Tasks',
        link: '/tasks',
        click: function (e: any) {
          setExpanded('tasks');
        }
      },

      {
        id: 'fields',
        label: 'Custom Fields',
        link: '/custom-fields',
        click: function (e: any) {
          setExpanded('fields');
        }
      }
    ],
    [workMenu, expanded]
  );

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
