import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContext } from 'contexts/auth';
import { isEmpty } from 'lodash';

const AuthProtected = (props: any) => {
  const { user } = useContext(AuthContext);

  if (isEmpty(user)) {
    return <Navigate to={{ pathname: '/login' }} />;
  }

  return <>{props.children}</>;
};

export default AuthProtected;
