import _ from 'lodash';
import { list, requestParams, retrieve, useDelete, useMutate } from './base';

export const getWorkCategories = (params?: requestParams) => {
  const url = '/work-categories';
  return list(url, params);
};

export const getWorks = (params?: requestParams) => {
  const url = '/work';
  return list(url, params);
};

export const getWorkFields = (params?: requestParams) => {
  const url = '/work-fields';
  return list(url, params);
};

export const getWork = (uid?: string) => {
  const url = `/work/${uid}`;
  return retrieve(url, !_.isEmpty(uid));
};
export const getItem = (uid?: string) => {
  const url = `/items/${uid}`;
  return retrieve(url, !_.isEmpty(uid));
};

export const getItems = (params?: requestParams) => {
  const url = '/items';
  return list(url, params);
};

export const getStatuses = (params?: requestParams & { item_type: string }) => {
  const { item_type } = params || {};
  const url = '/statuses';
  return list(url, params, !_.isEmpty(item_type));
};

export const getCategory = (uid?: string) => {
  const url = `/work-categories/${uid}`;
  return retrieve(url, !_.isEmpty(uid));
};

export const getStatus = (uid?: string) => {
  const url = `/statuses/${uid}`;
  return retrieve(url, !_.isEmpty(uid));
};

export const useMutateWorkCategories = () => {
  const url = `/work-categories`;
  return useMutate({ url });
};

export const useDeleteWorkCategories = () => {
  const url = `/work-categories`;
  return useDelete({ url });
};

export const useDeleteStatus = () => {
  const url = `/statuses`;
  return useDelete({ url });
};

export const useMutateWork = () => {
  const url = `/work`;
  return useMutate({ url });
};

export const useMutateStatus = () => {
  const url = `/statuses`;
  return useMutate({ url });
};

export const useDeleteWork = () => {
  const url = `/work`;
  return useDelete({ url });
};

export const useMutateWorkFields = () => {
  const url = `/work-fields`;
  return useMutate({ url });
};

export const useMutateItems = () => {
  const url = `/items`;
  return useMutate({ url });
};

export const useDeleteItem = () => {
  const url = `/items`;
  return useDelete({ url });
};
