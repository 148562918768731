import { AuthContext } from 'contexts/auth';
import { isEmpty } from 'lodash';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import withRouter from '../components/common/withRouter';

const NonAuthLayout = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const nonauthData = createSelector(
    (state) => state.Layout,
    (layoutModeType) => layoutModeType.layoutModeType
  );
  const layoutModeType = useSelector(nonauthData);

  useEffect(() => {
    if (layoutModeType === 'dark') {
      document.body.setAttribute('data-bs-theme', 'dark');
    } else {
      document.body.setAttribute('data-bs-theme', 'light');
    }
    return () => {
      document.body.removeAttribute('data-bs-theme');
    };
  }, [layoutModeType]);

  if (!isEmpty(user)) {
    navigate('/');
  }

  return <Outlet />;
};

export default withRouter(NonAuthLayout);
