import * as Sentry from '@sentry/react';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const refetchInterval = Number(process.env.REACT_APP_REFRESH_INTERVAL || 5) * 60 * 1000;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: refetchInterval - 1000,
      refetchInterval
    }
  },
  queryCache: new QueryCache({
    onError: (error: any) => {
      const { status, errors } = error;
      if (Number(status) >= 500) {
        Sentry.captureException(errors);
        toast.error('Sorry! An error occurred, please try again later.');
      }
    }
  })
});
