import axiosBase from 'axios';
import { clearStorage, localStorageJSON } from 'utils';
import config from '../config';

const { api } = config;
const axios = axiosBase.create();
// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(
  (config) => {
    const serviceToken = localStorageJSON('serviceToken');

    if (serviceToken) {
      config.headers['Authorization'] = `Token ${serviceToken}`;
    }
    if (config.method === 'get') {
      const cacheKey = localStorageJSON('cacheTracker');
      const params = config.params || {};
      if (cacheKey) {
        params['k'] = cacheKey;
      }

      config.params = params;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// intercepting to capture errors
axios.interceptors.response.use(
  (response) => {
    const cacheKey = response.headers['cache-version'];

    if (cacheKey) {
      localStorageJSON('cacheTracker', cacheKey);
    } else {
      localStorage.removeItem('cacheTracker');
    }
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let errors, resp_status;
    const { status, response, name, code } = error;
    resp_status = status;

    if (name === 'AxiosError' && code === 'ERR_NETWORK') {
      resp_status = 500;
      errors = error.message;
    }
    switch (status) {
      case 500:
        errors = 'Internal Server Error';
        break;
      case 401:
        errors = 'Invalid credentials';
        break;
      case 404:
        errors = 'Sorry! the data you are looking for could not be found';
        break;
      default:
        errors = error.message || error;
    }

    if (response) {
      const { status, data } = response;
      resp_status = status;
      if (status === 404) {
        errors = 'Requested resource not found';
      } else if (status >= 500 && status < 600) {
        errors = 'Server error';
      } else {
        const { status_code, ...others } = data;
        resp_status = status_code || status;
        errors = others;
        const { detail } = data;
        if (typeof detail == 'string') {
          const dLower = detail?.toLowerCase();
          if (dLower?.includes('credentials were not provided') || dLower?.includes('invalid token')) {
            clearStorage();
          }
        }
      }
    }

    return Promise.reject({ status: resp_status, errors });
  }
);

export default axios;
