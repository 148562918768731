import * as Sentry from '@sentry/react';
import { isBoolean, isEmpty, isNumber } from 'lodash';
export const fieldsWithOptions = ['text', 'number', 'email', 'phone', 'url'];
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const phoneRegex = /^\+?[0-9]\d{1,14}$/;

export const validateField = (field_type: string, value: string, fieldName = 'Field') => {
  let error;
  const multi = fieldName === 'Options';
  switch (field_type) {
    case 'number':
      if (isNaN(Number(value))) {
        error = `${fieldName} must be ${multi ? 'numbers' : 'a number'}`;
      }
      break;
    case 'email':
      if (!emailRegex.test(value)) {
        error = `${fieldName} must be ${multi ? '' : 'a '}valid email ${multi ? 'addresses' : 'address'}`;
      }
      break;
    case 'phone':
      if (!phoneRegex.test(value)) {
        error = `${fieldName} must be ${multi ? '' : 'a '}valid phone ${multi ? 'numbers' : 'number'}`;
      }
      break;
    case 'url':
      try {
        new URL(value);
      } catch {
        error = `${fieldName} must be ${multi ? '' : 'a '}valid ${multi ? 'URLs' : 'URL'}`;
      }
      break;
    default:
      break;
  }
  return error;
};

export const validateOptions = (field_type: string, value: string[]) => {
  let error;
  if (fieldsWithOptions.includes(field_type)) {
    if (!value || value.length === 0) {
      error = 'At least one option is required';
    } else {
      value.forEach((option) => {
        if (!option || option.trim() === '') {
          error = 'Options cannot be empty';
        } else {
          error = validateField(field_type, option, 'Options');
        }
      });
    }
  }
  return error;
};

export const titleCase = (str: string) => {
  if (!str) {
    return '';
  }
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const splitOnCapital = (str: string) => {
  if (!str) {
    return '';
  }
  const splitted = str
    .trim()
    .split(/(?=[A-Z])/)
    .map((element) => element.trim());
  return splitted.join(' ');
};

export const statusColor = (status: string) => {
  const lower = status?.toLowerCase();
  let color;

  switch (lower) {
    case 'to do':
      color = 'primary';
      break;
    case 'in progress':
      color = 'secondary';
      break;
    case 'blocked':
      color = 'danger';
      break;
    case 'done':
      color = 'success';
      break;
    case 'cancelled':
      color = 'info';
      break;
    default:
      color = 'light';
  }
  return color;
};

export const localStorageJSON = (key: string, value?: any) => {
  if (value) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    const data = localStorage.getItem(key);
    if (data) {
      try {
        return JSON.parse(data);
      } catch (err) {
        Sentry.captureException(err);
        return data;
      }
    }
  }
};

export const clearStorage = () => {
  Object.keys(localStorage).forEach((key) => {
    if (key === 'serviceToken' || isEmpty(localStorageJSON(key))) {
      localStorage.removeItem(key);
    }
  });
};

export const removeEmpty = (obj: { [s: string]: any }) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => isNumber(v) || isBoolean(v) || !isEmpty(v)));
};
