import { queryOptions } from '@tanstack/react-query';
import axios from 'helpers/api_helper';
import { clearStorage } from 'utils';
import { list, useMutate } from './base';

const refetchInterval = Number(process.env.REACT_APP_ME_REFRESH_INTERVAL || 0.5) * 60 * 1000;

export const getCurrentUser = () => {
  const url = '/me';
  return queryOptions({
    refetchInterval,
    staleTime: refetchInterval - 1000,
    refetchIntervalInBackground: true,
    queryKey: [url],
    queryFn: async () => {
      const resp = await axios.get(url);
      return resp.data;
    }
  });
};

export const useSignIn = () => {
  const url = `/auth/login`;
  return useMutate({ url, retryOn: 'network' });
};

export const useSignOut = () => {
  const url = `/auth/logout`;
  return useMutate({ url, onSuccess: clearStorage, invalidate: ['all'] });
};

export const useRequestCode = () => {
  const url = `/request-code`;
  return useMutate({ url });
};

export const useResetPassword = () => {
  const url = `/reset-password`;
  return useMutate({ url });
};

export const useSignOutAll = () => {
  const url = `/auth/logoutall`;
  return useMutate({ url, onSuccess: clearStorage, invalidate: ['all'] });
};

export const useUpdateProfile = () => {
  const url = `/me`;
  return useMutate({ url });
};

export const getUserSessions = () => {
  const url = '/sessions';
  return list(url, { refetchInterval });
};
