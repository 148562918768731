import { lazyRetry } from 'components/common/LazyRetry';
import Loadable from 'components/common/Loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import VerticalLayout from '../layouts/index';
import NonAuthLayout from '../layouts/NonAuthLayout';
import AuthProtected from './AuthProtected';

// const Blank = Loadable(lazy(() => lazyRetry(() => import('pages/blank'))));
const Activity = Loadable(lazy(() => lazyRetry(() => import('pages/admin/activity'))));
const Alt404 = Loadable(lazy(() => lazyRetry(() => import('pages/authentication-inner/errors/Alt404'))));
const Basic404 = Loadable(lazy(() => lazyRetry(() => import('pages/authentication-inner/errors/Basic404'))));
const Clients = Loadable(lazy(() => lazyRetry(() => import('pages/clients/clients'))));
const Cover404 = Loadable(lazy(() => lazyRetry(() => import('pages/authentication-inner/errors/Cover404'))));
const CustomFileldList = Loadable(lazy(() => lazyRetry(() => import('pages/fields/list'))));
const Error500 = Loadable(lazy(() => lazyRetry(() => import('pages/authentication-inner/errors/Error500'))));
const ForgotPasswordPage = Loadable(lazy(() => lazyRetry(() => import('pages/authentication/forgot-password'))));
const ResetPasswordPage = Loadable(lazy(() => lazyRetry(() => import('pages/authentication/reset-password'))));
const Home = Loadable(lazy(() => lazyRetry(() => import('pages/home'))));
const Login = Loadable(lazy(() => lazyRetry(() => import('pages/authentication/login'))));
const Portals = Loadable(lazy(() => lazyRetry(() => import('pages/portals/portals'))));
const Task = Loadable(lazy(() => lazyRetry(() => import('pages/work/task'))));
const Tasks = Loadable(lazy(() => lazyRetry(() => import('pages/settings/tasks/tasks'))));
const UserProfile = Loadable(lazy(() => lazyRetry(() => import('pages/authentication/user-profile'))));

const Users = Loadable(lazy(() => lazyRetry(() => import('pages/users/users'))));

const authProtectedRoutes = {
  path: '/',
  element: (
    <AuthProtected>
      <VerticalLayout />
    </AuthProtected>
  ),
  children: [
    { path: '/dashboard', element: <Home /> },
    { path: '/index', element: <Home /> },
    { path: '/profile', element: <UserProfile /> },
    { path: '/users', element: <Users /> },
    { path: '/custom-fields', element: <CustomFileldList /> },
    { path: '/tasks', element: <Tasks /> },
    { path: '/activity', element: <Activity /> },
    { path: '/clients', element: <Clients /> },
    { path: '/portals', element: <Portals /> },
    { path: '/tasks/:uid', element: <Task /> },
    {
      path: '/',
      exact: true,
      element: <Navigate to="/dashboard" />
    },
    { path: '*', element: <Navigate to="/dashboard" /> }
  ]
};

const publicRoutes = {
  path: '/',
  element: <NonAuthLayout />,
  children: [
    { path: '/login', element: <Login /> },
    { path: '/request-password-reset', element: <ForgotPasswordPage /> },
    { path: '/reset-password', element: <ResetPasswordPage /> },
    { path: '/auth-404-basic', element: <Basic404 /> },
    { path: '/auth-404-cover', element: <Cover404 /> },
    { path: '/auth-404-alt', element: <Alt404 /> },
    { path: '/auth-500', element: <Error500 /> }
  ]
};

export { authProtectedRoutes, publicRoutes };
