import React from 'react';
import { Spinner } from 'reactstrap';

import { toast } from 'react-toastify';

const Loader = (props: any) => {
  const { error } = props;
  return (
    <React.Fragment>
      <div className="d-flex justify-content-center mx-2 mt-2">
        <Spinner color="primary"> Loading... </Spinner>
      </div>
      {error && toast.error(error)}
    </React.Fragment>
  );
};

export default Loader;
