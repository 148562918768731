import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { getCurrentUser } from 'api/auth';
import ErrorFallback from 'components/common/ErrorFallback';
import Loader from 'components/common/Loader';
import { AuthContext } from 'contexts/auth';
import { isEmpty } from 'lodash';
import { Settings } from 'luxon';
import Error500 from 'pages/authentication-inner/errors/Error500';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/themes.scss';
import { router } from './routes';

Settings.throwOnInvalid = true;
Settings.defaultZone = 'Africa/Nairobi';
Settings.defaultLocale = 'en-KE';

function App() {
  const { data, isLoading, isError } = useQuery(getCurrentUser());

  if ((isLoading || data === undefined) && !isError) {
    return <Loader />;
  }
  try {
    if (isEmpty(data)) {
      Sentry.setUser(null);
    } else {
      Sentry.setUser({
        name: data.name,
        id: data.uid,
        email: data.email,
        username: data.username,
        is_active: data.is_active,
        is_staff: data.is_staff,
        account_number: data.account_number,
        has_password: data.has_password
      });
    }
  } catch {
    /* empty */
  }

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback error="" />} showDialog>
      <AuthContext.Provider value={{ user: data }}>{isError ? <Error500 /> : <RouterProvider router={router} />}</AuthContext.Provider>
      <ToastContainer />
    </Sentry.ErrorBoundary>
  );
}

export default App;
