import { combineReducers } from 'redux';
import LayoutReducer from './layouts/reducer';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  Layout: LayoutReducer
});

export default reducers;
